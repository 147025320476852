export default {
  apiKey: 'AIzaSyDmwOCsgb73b49ht8M3DenqyNQHPU6cosk',
  authDomain: 'noter.dev',
  databaseURL: 'https://noter-developer-default-rtdb.firebaseio.com',
  projectId: 'noter-developer',
  storageBucket: 'noter-developer.appspot.com',
  messagingSenderId: '755374420952',
  appId: '1:755374420952:web:fbd4badbe3d5793614f8ad',
  measurementId: 'G-ZEPQSKGE24',
  recaptchaSiteKey: '6Lc_0uYfAAAAAA9iIn74wUjoVt7t8CsPIIHnpWIY'
}
