<template>
  <p class="cmd">slime@noter-dev:~$ about</p>
  <spinner v-if="loading" />
  <template v-else>
    <p class="cmd-res">&nbsp;</p>
    <p class="cmd-res title">{{ fullname }}</p>
    <p class="cmd-res">&nbsp;</p>
    <p class="cmd-res sub-title"><i class="fa-fw far fa-graduation-cap"></i><span>Education</span></p>
    <template v-for="(edu, i) in education" :key="'edu-' + i">
      <p class="cmd-res">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-fw far fa-long-arrow-right"></i> {{ edu.degree }} / {{ edu.curriculum }}</p>
      <p class="cmd-res">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-fw far fa-map-pin"></i> {{ edu.academy }}</p>
      <p class="cmd-res">&nbsp;</p>
    </template>
    <p class="cmd-res sub-title"><i class="fa-fw far fa-flask-potion"></i><span>Experiences</span></p>
    <template v-for="(exp, i) in experiences" :key="'exp-' + i">
      <p class="cmd-res">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-fw far fa-long-arrow-right"></i> {{ exp.position }}</p>
      <p class="cmd-res">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-fw far fa-map-pin"></i> {{ exp.location }}</p>
      <p class="cmd-res">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-fw far fa-calendar-alt"></i> {{ exp.time }}</p>
      <p class="cmd-res">&nbsp;</p>
    </template>
    <p class="cmd-res sub-title"><i class="fa-fw far fa-scroll-old"></i><span>Skill</span></p>
    <p class="cmd-res">&nbsp;&nbsp;&nbsp;&nbsp;{{ skill }}</p>
    <p class="cmd-res">&nbsp;</p>
    <p class="cmd-res sub-title"><i class="fa-fw far fa-bullseye-arrow"></i><span>Interested</span></p>
    <p class="cmd-res">&nbsp;&nbsp;&nbsp;&nbsp;{{ interested }}</p>
    <p class="cmd">slime@noter-dev:~$ contact</p>
    <p class="cmd-res menu-link" v-for="(contact, i) in contacts" :key="'contact-' + i">
      <a target="_blank" :href="contact.href"><i :class="'fa-fw ' + contact.icon"></i><span>{{ contact.name }}</span></a>
    </p>
    <p class="cmd">slime@noter-dev:~$ quote</p>
    <p class="cmd-res"><i class="fa-fw far fa-quote-left"></i> {{ quote }} <i class="fa-fw far fa-quote-right"></i></p>
  </template>
</template>

<script>
import { doc, getDoc } from 'firebase/firestore/lite'
import Spinner from '../components/Spinner'

export default {
  name: 'About',
  props: ['firestore'],
  components: {
    Spinner
  },
  emits: ['rendered', 'moved'],
  data () {
    return {
      loading: true,
      fullname: '',
      education: [],
      experiences: [],
      skill: '',
      interested: '',
      contacts: [],
      quote: ''
    }
  },
  async mounted () {
    try {
      const snapshot = await getDoc(doc(this.firestore, 'noter-dev', 'about'))
      this.fullname = snapshot.get('fullname') || ''
      this.education = (snapshot.get('educations') || []).reverse()
      this.experiences = (snapshot.get('experiences') || []).reverse()
      this.skill = snapshot.get('skill') || ''
      this.interested = snapshot.get('interested') || ''
      this.contacts = snapshot.get('contacts') || []
      this.quote = snapshot.get('quote') || ''
    } catch {
      this.fullname = 'DEMON SLIME'
      this.education = [
        {
          degree: 'Master',
          curriculum: 'Around the world',
          academy: 'Slime University'
        }
      ]
      this.experiences = [
        {
          position: 'Engineer',
          location: 'Jura Tempest Federation',
          time: `${(new Date()).getFullYear() - 300} - ${(new Date()).getFullYear()}`
        }
      ]
      this.skill = '???'
      this.interested = '???'
      this.contacts = [
        {
          icon: 'far fa-book-spells',
          name: 'Telepathy Net',
          href: '/'
        }
      ]
      this.quote = 'I\'m not a bad slime.'
    } finally {
      this.loading = false
      this.$emit('rendered')
    }
  },
  unmounted () {
    this.$emit('moved')
  }
}
</script>
