<template>
  <div class="bar">
    <h4 class="title"><i class="far fa-terminal"></i> Terminal</h4>
  </div>
  <div class="windows">
    <p class="cmd-res">Last login: {{ lastLogin }}</p>
    <template v-if="mainPage === null">
      <spinner/>
    </template>
    <template v-else-if="mainPage">
      <p class="cmd">slime@noter-dev:~$ menu</p>
      <menu-list to="/" name="About" />
      <menu-list to="/projects" name="Projects" />
      <menu-list to="/logs" name="Logs" />
      <router-view :firestore="firestore" @rendered="rendered" @moved="moved"/>
    </template>
    <template v-else>
      <p class="cmd">slime@noter-dev:~$ sudo render</p>
      <p class="cmd-res">Error: Cannot access content. Please refresh to try again.</p>
    </template>
    <template v-if="ended">
      <p class="cmd">slime@noter-dev:~$ exit</p>
      <p class="cmd-res">Balance: Unlimited</p>
      <p class="cmd-res">...</p>
      <p class="cmd-res">[Completed]</p>
      <p class="cmd-res">&nbsp;</p>
    </template>
  </div>
</template>

<script>
import MenuList from './components/MenuList'
import Spinner from './components/Spinner'
import { getToken } from 'firebase/app-check'

export default {
  name: 'App',
  props: ['firestore', 'appCheck'],
  components: {
    MenuList,
    Spinner
  },
  data () {
    return {
      mainPage: null,
      ended: false
    }
  },
  computed: {
    lastLogin () {
      return new Date().toISOString()
    }
  },
  mounted () {
    this.waitAppCheck()
  },
  methods: {
    async waitAppCheck () {
      try {
        await getToken(this.appCheck)
        this.mainPage = true
      } catch {
        this.mainPage = false
        this.ended = true
      }
    },
    rendered () {
      this.ended = true
    },
    moved () {
      this.ended = false
    }
  }
}
</script>

<style>
html, body {
  background-color: #000;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  padding-top: env(safe-area-inset-top);
  height: calc(100% - env(safe-area-inset-top));
}
#app {
  font-family: 'Hack', monospace;
  font-size: 16px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 8px;
  width: calc(100% - (4px + env(safe-area-inset-left) + env(safe-area-inset-right)));
  height: calc(100% - (4px + env(safe-area-inset-bottom)));
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);
}
.bar {
  border-bottom: 1px solid #fff;
  padding: 12px;
  height: 18px;
}
.bar > .title {
  margin: 0;
  text-align: center;
}
.windows {
  border-top: 1px solid #fff;
  padding: 8px;
  height: calc(100% - 59px);
  overflow-y: auto;
}
p.cmd {
  margin-bottom: 0;
}
p.cmd:first-child {
  margin-top: 0;
}
p.cmd-res {
  margin: 0;
}
p.cmd-res.menu-link {
  margin: 0.25rem 0;
}
p.cmd-res.menu-link > a:not(.router-link-active) > .active {
  display: none;
}
p.cmd-res.menu-link > a.router-link-active > .default {
  display: none;
}
p.cmd-res.menu-link > a:not(.router-link-active):hover > span {
  text-decoration: underline;
}
p.cmd-res > span {
  margin-left: 8px;
}
p.cmd-res.title {
  font-weight: bold;
}
p.cmd-res.sub-title {
  font-weight: bold;
}
p.cmd-res > a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
p.cmd-res > a > span {
  margin-left: 8px;
}
p.cmd-res > a:hover > span {
  text-decoration: underline;
}
</style>
