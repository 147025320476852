<template>
  <p class="cmd">slime@noter-dev:~$ logs</p>
  <spinner v-if="loading" />
  <template v-else>
    <p class="cmd-res"><i class="fa-fw far fa-grip-lines-vertical"></i></p>
    <template v-for="acm in achievements" :key="'acm-' + acm.year">
      <p class="cmd-res"><i class="fa-fw far fa-scrubber"></i><span>{{ acm.year }}</span></p>
      <p class="cmd-res"><i class="fa-fw far fa-grip-lines-vertical"></i></p>
      <template v-for="(timeline, i) in acm.timeline" :key="'timeline-' + acm.year + '-' + i">
        <p class="cmd-res"><i class="fa-fw far fa-grip-lines-vertical"></i>&nbsp;&nbsp;&nbsp;&nbsp;{{ timeline.time }}</p>
        <p class="cmd-res"><i class="fa-fw far fa-grip-lines-vertical"></i>=&gt;&nbsp;&nbsp;{{ timeline.name }}</p>
        <p class="cmd-res"><i class="fa-fw far fa-grip-lines-vertical"></i></p>
      </template>
    </template>
  </template>
</template>

<script>
import { doc, getDoc } from 'firebase/firestore/lite'
import Spinner from '../components/Spinner'

export default {
  name: 'Logs',
  props: ['firestore'],
  components: {
    Spinner
  },
  emits: ['rendered', 'moved'],
  data () {
    return {
      loading: true,
      achievements: []
    }
  },
  async mounted () {
    try {
      const snapshot = await getDoc(doc(this.firestore, 'noter-dev', 'achievements'))
      const acm = (snapshot.data() || {})
      const achievements = []
      for (const year in acm) {
        achievements.push({
          year: year,
          timeline: (acm[year] || []).reverse()
        })
      }
      achievements.sort((a, b) => b.year.localeCompare(a.year))
      this.achievements = achievements
    } catch {
      this.achievements = [
        {
          year: `${(new Date()).getFullYear() - 1}`,
          timeline: [
            {
              time: `January - December ${(new Date()).getFullYear() - 1}`,
              name: 'Slime'
            }
          ]
        }
      ]
    } finally {
      this.loading = false
      this.$emit('rendered')
    }
  },
  unmounted () {
    this.$emit('moved')
  }
}
</script>
