import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initializeApp } from 'firebase/app'
import { initializeFirestore } from 'firebase/firestore/lite'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { initializeAnalytics } from 'firebase/analytics'
import { initializePerformance } from 'firebase/performance'
import config from './firebase.config'

if (process.env.NODE_ENV !== 'production') {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

const firebaseApp = initializeApp(config)
const firestore = initializeFirestore(firebaseApp)
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(config.recaptchaSiteKey),
  isTokenAutoRefreshEnabled: true
})

if (process.env.NODE_ENV === 'production') {
  initializeAnalytics(firebaseApp)
  initializePerformance(firebaseApp)
}

createApp(App, { firestore, appCheck }).use(router).mount('#app')
