<template>
  <p class="cmd">slime@noter-dev:~$ projects</p>
  <spinner v-if="loading" />
  <template v-else>
    <template v-for="(project, i) in projects" :key="'project-' + i">
      <p class="cmd-res">&nbsp;</p>
      <p class="cmd-res"><i class="fa-fw far fa-long-arrow-right"></i> {{ project.name }}</p>
      <p class="cmd-res" v-if="project.desc">&nbsp;&nbsp;&nbsp;Description: {{ project.desc }}<span v-if="project.time">- {{ project.time }}</span></p>
      <p class="cmd-res" v-if="project.stack">&nbsp;&nbsp;&nbsp;Stack: {{ project.stack }}</p>
      <p class="cmd-res" v-if="project.link">&nbsp;&nbsp;&nbsp;<a class="project-link" target="_blank" :href="project.link"><i class="fa-fw far fa-external-link-alt"></i><span>{{ project.link }}</span></a></p>
    </template>
  </template>
</template>

<script>
import { doc, getDoc } from 'firebase/firestore/lite'
import Spinner from '../components/Spinner'

export default {
  name: 'Projects',
  props: ['firestore'],
  components: {
    Spinner
  },
  emits: ['rendered', 'moved'],
  data () {
    return {
      loading: true,
      projects: []
    }
  },
  async mounted () {
    try {
      const snapshot = await getDoc(doc(this.firestore, 'noter-dev', 'projects'))
      this.projects = (snapshot.get('projects') || []).reverse()
    } catch {
      this.projects = [
        {
          name: 'New Dungeon',
          desc: '100 floor dungeon',
          time: `${(new Date()).getFullYear()}`,
          stack: 'Personal Skill',
          link: ''
        }
      ]
    } finally {
      this.loading = false
      this.$emit('rendered')
    }
  },
  unmounted () {
    this.$emit('moved')
  }
}
</script>
