<template>
  <p class="cmd-res">{{ now }}</p>
</template>

<script>
export default {
  name: 'Loading',
  data () {
    return {
      interval: 0,
      now: '|'
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      if (this.now === '|') this.now = '/'
      else if (this.now === '/') this.now = '-'
      else if (this.now === '-') this.now = '\\'
      else if (this.now === '\\') this.now = '|'
    }, 100)
  },
  unmounted () {
    clearInterval(this.interval)
  }
}
</script>
