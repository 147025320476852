<template>
  <p class="cmd-res menu-link">
    <router-link :to="to">
      <i class="fa-fw far fa-arrow-circle-right active"></i>
      <i class="fa-fw far fa-circle default"></i>
      <span>{{ name }}</span>
    </router-link>
  </p>
</template>

<script>
export default {
  name: 'MenuList',
  props: {
    to: {
      type: String,
      default: '/'
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>
